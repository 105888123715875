import React, { useState, useEffect } from "react";
import { Stack, TextField, Checkbox, Rating, PrimaryButton, IStackTokens, Label } from "@fluentui/react";
import styles from "./FeedbackPanel.module.css"; // Import the CSS module
import { grayscale } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { getToken, useLogin } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { FeedbackOptions, Feedback, submitFeedback } from "../../api";

interface Props {
    positive: boolean;
    answerIndex: number;
}

export const FeedbackPanel = ({ positive, answerIndex }: Props) => {
    const [feedbackOptions, setFeedbackOptions] = useState<FeedbackOptions>({
        sourceNotFound: false,
        innacurate: false,
        verbose: false,
        wrongCitations: false
    });
    const [comments, setComments] = useState<string>("");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<unknown>();

    const stackTokens: IStackTokens = { childrenGap: 15 };

    const handleCheckboxChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
        const { name } = event?.target as HTMLInputElement;
        setFeedbackOptions(prevState => ({
            ...prevState,
            [name]: checked || false
        }));
    };

    const client = useLogin ? useMsal().instance : undefined;

    const onSubmitFeedback = async () => {
        try {
            setSubmitting(true);
            const token = client ? await getToken(client) : undefined;

            const elementId = `chatanswer${answerIndex + 1}`;
            const element = document.getElementById(elementId);

            // Instantiate the Feedback object using the current state values
            const feedback: Feedback = {
                ...feedbackOptions,
                message: comments,
                isPositive: positive,
                answerLogId: element?.innerText || ""
            };

            const response = await submitFeedback(feedback, token);

            if (response.status > 299 || !response.ok) {
                throw Error(`Request failed with status ${response.status}`);
            }

            // Reset state values after successful submission
            setFeedbackOptions({
                sourceNotFound: false,
                innacurate: false,
                verbose: false,
                wrongCitations: false
            });
            setComments("");
            setError(undefined);
            setMessage("Feedback submitted successfully!");
        } catch (e) {
            setError(e);
            setMessage("An error occurred while submitting feedback. Please try again.");
        } finally {
            setSubmitting(false);
        }
    };

    // Effect to clear message after 3-5 seconds
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage(null);
            }, 3000); // 3 seconds (or adjust to 5000 for 5 seconds)

            return () => clearTimeout(timer);
        }
    }, [message]);

    const checkBoxStyles = {
        rootHovered: {
            color: "yellow"
        },
        checkmark: {
            background: "yellow",
            color: "white"
        },
        checkbox: {
            background: "white",
            borderColor: "black"
        },
        text: {
            fontWeight: "bold",
            color: "white"
        },
        textHovered: {
            fontWeight: "bold",
            color: "white" // Ensure text remains white on hover
        },
        checkboxHovered: {
            borderColor: "black", // Keep the border color consistent on hover
            background: "white" // Keep the background consistent on hover
        }
    };

    return (
        <Stack tokens={stackTokens} style={{ padding: 20, backgroundColor: "#6E6E6E" }}>
            <h3 className={styles.heading}>Share Your Feedback</h3>

            {/* Checkbox Options */}
            {!positive && (
                <Stack tokens={{ childrenGap: 10 }}>
                    <Checkbox
                        label="Expected Source Not Found"
                        name="sourceNotFound"
                        checked={feedbackOptions.sourceNotFound}
                        onChange={handleCheckboxChange}
                        styles={checkBoxStyles}
                    />
                    <Checkbox
                        label="Wrong Citations"
                        name="wrongCitations"
                        checked={feedbackOptions.wrongCitations}
                        onChange={handleCheckboxChange}
                        styles={checkBoxStyles}
                    />
                    <Checkbox
                        label="Innacurate or Incoherent"
                        name="innacurate"
                        checked={feedbackOptions.innacurate}
                        onChange={handleCheckboxChange}
                        styles={checkBoxStyles}
                    />
                    <Checkbox label="Verbose" name="verbose" checked={feedbackOptions.verbose} onChange={handleCheckboxChange} styles={checkBoxStyles} />
                </Stack>
            )}

            <TextField
                label={positive ? "Tell Us What You Liked in This Answer." : "Tell Us How We Can Improve This Answer."}
                multiline
                rows={4}
                value={comments}
                onChange={(e, newValue) => setComments(newValue || "")}
                className={`${styles.textFieldRoot} ${styles.textFieldField}`}
            />
            <div>{message && <div className={message.includes("error") ? styles.errorMessage : styles.successMessage}>{message}</div>}</div>
            {/* Submit button */}
            <PrimaryButton
                text="Submit Feedback"
                className={styles.buttonRoot}
                onClick={() => {
                    onSubmitFeedback();
                }}
                disabled={comments.length === 0 || submitting}
            />
        </Stack>
    );
};
