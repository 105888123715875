import { Example } from "./Example";
import { useTranslation } from "react-i18next";

import styles from "./Example.module.css";

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

// Define ExampleData type to represent each example structure
interface ExampleData {
    title: string;
    text: string;
    prompt: string;
}

export const ExampleList = ({ onExampleClicked, useGPT4V }: Props) => {
    const { t } = useTranslation();

    // Use ExampleData type for both example arrays
    const DEFAULT_EXAMPLES: ExampleData[] = [
        { title: t("defaultExamples.1.title"), text: t("defaultExamples.1.text"), prompt: t("defaultExamples.1.prompt") },
        { title: t("defaultExamples.2.title"), text: t("defaultExamples.2.text"), prompt: t("defaultExamples.2.prompt") },
        { title: t("defaultExamples.3.title"), text: t("defaultExamples.3.text"), prompt: t("defaultExamples.3.prompt") }
    ];

    const GPT4V_EXAMPLES: ExampleData[] = [
        { title: t("gpt4vExamples.1.title"), text: t("gpt4vExamples.1.text"), prompt: t("gpt4vExamples.1.prompt") },
        { title: t("gpt4vExamples.2.title"), text: t("gpt4vExamples.2.text"), prompt: t("gpt4vExamples.2.prompt") },
        { title: t("gpt4vExamples.3.title"), text: t("gpt4vExamples.3.text"), prompt: t("gpt4vExamples.3.prompt") }
    ];

    return (
        <ul className={styles.examplesNavList}>
            {(useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES).map((question, i) => (
                <li key={i}>
                    <Example title={question.title} text={question.text} value={question.prompt} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
